<script setup>
const props = defineProps({
  noAside: {
    type: Boolean,
    default: false
  }
});
</script>

<template>
  <div class="flex gap-6">
    <div 
      v-for="(item, index) in 4" 
      :key="index" 
      :class="[
        'group relative mb-6 rounded-md shadow-box select-none transition-all duration-300 animate-pulse bg-white',
        props.noAside ? 'w-[300px] min-h-[320px]' : 'w-[300px] min-h-[464px]'
      ]"
    >
    </div>
  </div>
</template>
