<script setup>
const props = defineProps({
  noAsideCards: Boolean,
  isShadowBoxed: {
    type: Boolean,
    default: () => false,
  },
  isWhiteCard: {
    type: Boolean,
    default: () => false,
  },
  articles: {
    type: Array,
    default: () => [],
  },
});

const refSlideControl = ref();
const refSlideNavigation = ref();

const updateSlideNumber = (slideNumber) => {
  refSlideNavigation.value.setSlide(slideNumber);
};

const scrollToSlide = (slide) => {
  refSlideControl.value.scrollToSlide(slide);
};
</script>

<template>
  <template v-if="noAsideCards">
    <SlideControl ref="refSlideControl"
                  class="-mt-4"
                  @swipeEnd="(slideNumber) => updateSlideNumber(slideNumber)"
    >
      <template v-for="(item, index) in articles" :key="index">
        <SlideItem :heightFull="isWhiteCard" blogDesktopWidth>
          <div class="gap-6 flex flex-col"
          :class="{'h-full': isWhiteCard}"
          >
            <BlogMainCard
              :item="item"
              :is-white-card="isWhiteCard"
              :is-shadow-boxed="isShadowBoxed"
            />
          </div>
        </SlideItem>
      </template>
    </SlideControl>

    <SlideNavigation ref="refSlideNavigation"
                     class="-mt-4"
                     :items="articles.length"
                     @changed="(slide) => scrollToSlide(slide)"
    />
  </template>

  <template v-else>
    <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6 mb-6">
      <template v-for="(item, index) in articles.slice(0, 4)" :key="index">
        <div class="box">
          <BlogMainCard
            :item="item"
            :is-white-card="isWhiteCard"
            :is-shadow-boxed="isShadowBoxed"
          />
        </div>
      </template>
    </div>

    <SlideControl ref="refSlideControl"
                  class="-mt-4"
                  @swipeEnd="(slideNumber) => updateSlideNumber(slideNumber)"
    >
      <template v-for="(item, index) in articles.slice(4, 12)" :key="index">
        <SlideItem  blogDesktopWidth>
          <div class="flex flex-col gap-4">
            <BlogAsideCard
              :position="'Najnowsze'"
              :item="item"
            />
          </div>
        </SlideItem>
      </template>
    </SlideControl>

    <SlideNavigation ref="refSlideNavigation"
                     class="-mt-4"
                     :items="articles.slice(4, 12).length"
                     @changed="(slide) => scrollToSlide(slide)"
    />
  </template>
</template>
