<script setup>

</script>

<template>
  <div class="group relative w-full min-h-[200px]
              rounded-md shadow-box select-none
              transition-all duration-300 animate-pulse bg-white">
  </div>
</template>
