<script setup>
const analytics = useAnalytics();
const props = defineProps({
    featured: {
        type: Object,
        default: () => ({})
    }
});
</script>
<template> 
    <NuxtLink v-if="!featured?.category?.is_ad" :to="`/moto/artykul/${featured?.slug}`" class="grid grid-cols-1 md:grid-cols-2 gap-0 lg:max-h-[316px] lg:h-full">
        <div class="relative">
            <img class="md:absolute md:top-0 md:left-0 object-cover w-full h-full rounded-tl-md rounded-bl-md" :src="featured?.main_photo"/>
        </div>
        <div class="bg-dark-gray rounded-tr-md rounded-br-md px-6 py-4 relative 2xl flex flex-col justify-center">
            <p class="absolute -top-10 max-w-[120px] md:top-auto left-0 md:left-auto md:relative rounded-tr-md md:rounded-bl-md h-10 px-4 py-4 bg-dark-red text-white font-semibold mb-3 inline-flex items-center justify-center">
                {{featured?.category?.name}}
            </p>
            <p class="mb-3 text-2xl font-normal text-white">
                {{featured?.title}}
            </p>
            <p class="text-sm font-extralight text-white">{{featured?.publication_date}}</p>
        </div>
    </NuxtLink>
    <NuxtLink 
        v-else 
        :to="featured?.ad_link" 
        class="grid grid-cols-1 md:grid-cols-2 gap-0  lg:max-h-[316px] h-full"
        @click="analytics.analyticsLayer({
                event: 'select_promotion',
                creative_name: featured?.title ? featured?.title : '',
                type: 'Top',
                slot: 'Reklama',
                url: featured?.ad_link
            })"
        >
        <div class="relative">
            <img class="md:absolute md:top-0 md:left-0 object-cover w-full h-full rounded-tl-md rounded-bl-md" :src="featured?.main_photo"/>
        </div>
        <div class="bg-dark-gray rounded-tr-md rounded-br-md px-6 py-4 relative 2xl  flex flex-col justify-center">
            <p class="absolute -top-10 max-w-[120px] md:top-auto left-0 md:left-auto md:relative rounded-tr-md md:rounded-bl-md h-10 px-4 py-4 bg-dark-red text-white font-semibold mb-3 inline-flex items-center justify-center">
                {{featured?.category?.name}}
            </p>
            <p class="mb-3 text-2xl font-normal text-white">
                {{featured?.title}}
            </p>
            <p class="text-sm font-extralight text-white">{{featured?.publication_date}}</p>
        </div>
    </NuxtLink>
</template>